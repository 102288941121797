<template>
  <div class="bigDIv">
    <div class="bigImg">
      <img src="../../assets/images/shiliao/top1.jpg" alt="">
      <div>
        <van-form @submit="onSubmit">
          <van-field v-model="username" label="+86" placeholder="请输入您的手机号" :rules="[{ required: true}]" />
          <van-field v-model="password" placeholder="请输入验证码" :rules="[{ required: true }]">
            <template #button>
              <van-button size="small" type="primary">获取验证码</van-button>
            </template>
          </van-field>
          <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">立即报名</van-button>
          </div>
        </van-form>
      </div>
      <img src="../../assets/images/shiliao/bgc2.jpg" alt="">

    </div>
    <!-- // <script src="https://res2.wx.qq.com/open/js/jweixin-1.6.0.js"></script>
// <script src="https://res.wx.qq.com/open/js/jweixin-1.6.0.js"></script> -->
  </div>
</template>

 

<script>

import { getWechatConfig } from "@/api/yangsheng";
export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    return {
      password: '',
      username: '',
      group: '',
    }
  },
  created () {
    this.group = this.$route.query.group
  },
  methods: {
    // 分享
    // 获取微信jsdk
    getWechatConfig () {
      console.log(wx, 888888888);

      const query = {
        company_id: 1,
        url: window.location.href
      }
      getWechatConfig(query).then(res => {
        const agent = res.data.data
        if (res.data.code === 200) {
          this.$nextTick(() => {

          })
          wx.config({
            debug: agent.debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: agent.appId, // 必填，公众号的唯一标识
            timestamp: agent.timestamp, // 必填，生成签名的时间戳
            nonceStr: agent.nonceStr, // 必填，生成签名的随机串
            signature: agent.signature,// 必填，签名
            //1.4.0的分享接口,注意：使用新版本的分享功能，一定要在该列表加上对应的老版本功能接口，否则新接口不起作用
            //     "onMenuShareAppMessage", //老版本分享接口。
            //     "onMenuShareTimeline" //老版本分享接口。
            jsApiList: ["updateAppMessageShareData", "updateTimelineShareData,'onMenuShareAppMessage", "onMenuShareTimeline"] // 必填，需要使用的JS接口列表
          });

          this.share()
        }

      }
      )
    },
    share () {
      this.$nextTick(() => {
        wx.ready(function () {
          // 分享给朋友
          wx.updateAppMessageShareData({
            title: '血管年龄查询', // 分享标题
            link: 'https://kf.hshwhkj.com/shiliaoA', //这里可带参数，必须是同配置的JS安全域名一致
            desc: '三道题测出血管的真实年龄', // 分享描述
            imgUrl: 'https://oss.hshwhkj.com/hshcrm/image/2024/07/09/172049606735669199.png',// 必须是同配置的JS安全域名一致

            success: function () { }
          });
          // 分享到朋友圈
          wx.updateTimelineShareData({
            title: '血管年龄查询', // 分享标题
            link: 'https://kf.hshwhkj.com/shiliaoA', //这里可带参数，必须是同配置的JS安全域名一致
            desc: '三道题测出血管的真实年龄', // 分享描述
            imgUrl: 'https://oss.hshwhkj.com/hshcrm/image/2024/07/09/172049606735669199.png',// 必须是同配置的JS安全域名一致
            success: function () { }
          })

        });
        wx.error(res => {
          console.log('微信分享错误信息：', res)
        })
      })



    },
    onSubmit () {
      // const token = localStorage.getItem("token");
      // if (token) {
      this.$router.push({
        path: "/bloodInspect",
        query: {
          group: this.group,
        }
      });
      // }else{
      // this.getCode()

      // }
    },
    async getCode (isCode) {
      console.log(123, "获取code");

      let scope = "snsapi_userinfo"; //手动授权
      let info = {
        appid: "wxecd388507aac29b6"
      };
      // if (this.info.belong === 2) {
      //   info = {
      //     appid: "wx64571f72789c7dd3"
      //   };
      // } else if (this.info.belong === 3) {
      //   info = {
      //     appid: "wx109c3a30148f3fb8"
      //   };
      // } else if (this.info.belong === 7) {
      //   info = {
      //     appid = ""
      //   }
      // }

      // const res = await getCompanyById({
      //   id: this.info.company_id
      // });
      // info.appid = res.data.app_id;
      // !isCode
      // const token = sessionStorage.getItem("assice_token");
      // if (this.$route.query.code && !token) {
      //   this.getAccountOauthCodes();

      //   this.loading = true;
      //   console.log(this.$route.query.code, "我是code");

      //   // this.getOpenId(this.$route.query.code as string);
      // } else if (this.$route.query.code && token) {
      //   // TODO
      // } else {
      const parsedUrl = new URL(window.location.href);
      const basAAA = parsedUrl.origin;
      // channelLogin: this.$route.query.channelLogin || ""
      // nickname: this.$route.query.nickname || ""
      const redirectUrl = basAAA;
      // const redirectUrl = "http://localhost:8081";
      // `https://open.weixin.qq.com/connect/oauth2/authorize?appid=APPID&redirect_uri=REDIRECT_URI&response_type=code&scope=SCOPE&state=STATE#wechat_redirect`;
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${info.appid
        }&redirect_uri=${encodeURIComponent(
          `${redirectUrl}/bloodInspect?name=${this.username}&group=${this.group}`
        )}&response_type=code&scope=${scope}#wechat_redirect`;
      // }
    },
  },
  mounted () {
    this.getWechatConfig()
  },
  watch: {

  },
  computed: {

  },
  filters: {

  }
}
</script>

<style scoped lang='less'>
.bigDIv {
  width: 100%;
}
.bigImg {
  margin: 0 auto;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  img {
    width: 100%;
    object-fit: contain;
  }
}
</style>